import React, { useEffect, useState } from "react";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";

//var bnrimg = require("./../../images/banner/4.jpg");
//var bnrimg = require('./../../images/background/bg-22.jpg');

const ServiceDetail = () => {
  const { servicename } = useParams();
  const [moreServices, setMoreServices] = useState([]);
  const [services, setServices] = useState([]);
  const [backgroundImage,setBackGroundImage]=useState("")
  const [bannerImage,setBannerImage]=useState("")
  const navigate = useNavigate();
  useEffect(() => {
    if (servicename.toLocaleLowerCase() === "construction-and-engineering") {
      setBannerImage(require('./../../images/new-images/back-view-man-holding-building-plans.jpg'))
        setBackGroundImage(require("./../../images/new-images/beautiful-view-construction-site-city-sunset.jpg"))
      setMoreServices([
        "Electrical & Plumbing Works",
        "Civil Works",
        "Additional Services",
      ]);
      setServices([
        "Villas",
        "Flats",
        "Residential Projects",
        "Commercial Projects",
        "Civil Engineering Works",
        "Construction of Buildings",
      ]);
    } else if (
      servicename.toLocaleLowerCase() === "electrical-&-plumbing-works"
    ) {
      setBannerImage(require('./../../images/new-images/electrician-with-tools-working-construction-site-repair-handyman-concept.jpg'))
        setBackGroundImage(require("./../../images/new-images/electrician-with-tools-working-construction-site-repair-handyman-concept.jpg"))
      setMoreServices([
        "Construction and Engineering",
        "Civil Works",
        "Additional Services",
      ]);
      setServices([
        "Supply and installation of modern wiring and electrical equipment as per DEWA standards",
        "Repairing existing facilities",
        "Upgrading existing power facilities",
        "New connections (electricity & water)",
        "Installation and repair of all plumbing accessories",
      ]);
    } else if (servicename.toLocaleLowerCase() === "civil-works") {
      setBannerImage(require('./../../images/new-images/construction-site.jpg'))
        setBackGroundImage(require("./../../images/new-images/construction-site.jpg"))
      setMoreServices([
        "Construction and Engineering",
        "Electrical & Plumbing Works",
        "Additional Services",
      ]);
      setServices([
        "Block works",
        "Plastering works",
        "Painting works",
        "Interlock works",
        "Tiling works",
        "Design for special types of flooring",
        "Crack repair works using special types of chemicals",
      ]);
    } else if (servicename.toLocaleLowerCase() === "additional-services") {
      setBannerImage(require('./../../images/new-images/construction-silhouette.jpg'))
        setBackGroundImage(require("./../../images/new-images/construction-silhouette.jpg"))
      setMoreServices([
        "Construction and Engineering",
        "Electrical & Plumbing Works",
        "Civil Works",
      ]);
      setServices([
        "Maintenance of existing AC systems (gas filling, repairing, and cleaning)",
        "Package units from purchase to installation and maintenance",
        "Household electrical services",
        "Annual maintenance contracts for commercial and residential buildings and villas",
      ]);
    } else {
      navigate("/");
    }
  }, [servicename, navigate]);
  return (
    <>
      <Header />
      <div className="page-content">
        <Banner
          title={servicename.replaceAll("-", " ")}
          pagename=""
          description=""
          bgimage={bannerImage}
        />
        {/* SECTION CONTENT START */}
        <div className="section-full p-t80 p-b50 mobile-page-padding">
          <div className="container">
            {/* GALLERY CONTENT START */}
            <div className="row">
              <div className="col-lg-4 col-md-12 sticky_column">
                <div className="about4-section m-b20 bg-gray p-a20">
                  <div className="widget widget_services">
                    <h4 className="widget-title">More Services</h4>
                    <ul className="bg-white">
                      {moreServices.map((service, index) => {
                        return (
                          <li key={index}>
                            <NavLink
                              to={"/service/" + service.replaceAll(" ", "-")}
                            >
                              {service}
                            </NavLink>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                  {/* <div className="wt-icon-box-wraper left bg-white p-a15 m-b10">
                    <NavLink to={"/services-1"} className="btn-block">
                      <span className="text-black m-r10">
                        <i className="fa fa-file-pdf-o" />
                      </span>
                      <strong className="text-uppercase text-black">
                        Download .PDF
                      </strong>
                    </NavLink>
                  </div>
                  <div className="wt-icon-box-wraper left bg-white p-a15 m-b10">
                    <NavLink to={"/services-1"} className="btn-block">
                      <span className="text-black m-r10">
                        <i className="fa fa-file-word-o" />
                      </span>
                      <strong className="text-uppercase text-black">
                        {" "}
                        Download .DOC
                      </strong>
                    </NavLink>
                  </div>
                  <div className="wt-icon-box-wraper left bg-white p-a15 m-b10">
                    <NavLink to={"/services-1"} className="btn-block">
                      <span className="text-black m-r10">
                        <i className="fa fa-file-powerpoint-o" />
                      </span>
                      <strong className="text-uppercase text-black">
                        Download .PPT
                      </strong>
                    </NavLink>
                  </div> */}
                  {/* <div className="about4-content m-t30">
                    <h4>GET 10% OFF TODAY</h4>
                    <p>
                      A Completely Safe and Advanced Cleaning Solution for both
                      Petrol and Diesel Engines
                    </p>
                    <NavLink to={"/services-1"} className="site-button-link">
                      Read More
                    </NavLink>
                  </div> */}
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="section-content">
                  <div className="service-single-block m-b30">
                    <div className="sx-media">
                      <img
                        src={backgroundImage}
                        alt=""
                      />
                    </div>
                    <h3 className="m-t30 sx-tilte">
                      {/* Interior design, a fine line with more shine a design
                      gives you. */}
                    </h3>
                    <p>
                      We are pleased to introduce Al Diyar General Contracting
                      as a well-established provider of Building Contracting and
                      General Maintenance services in Abu Dhabi.
                    </p>
                    <p>
                      With a long-standing reputation for excellence, Al Diyar
                      General Contracting is committed to exceptional customer
                      service, proficient project management, and the creativity
                      and flexibility required to construct and maintain
                      outstanding structures. As a multi-faceted company, Al
                      Diyar General Contracting operates in various industries,
                      delivering superior services through its Land Contracting
                      and General Maintenance divisions.
                    </p>
                    <p>
                      We bring a fresh and innovative approach to our services,
                      aiming to exceed the expectations of every client. Our
                      industry professionals, managers, and staff are
                      distinguished by their functional and technical expertise,
                      combined with hands-on experience, ensuring clients
                      receive the most effective and professional service.
                    </p>
                    <h4 className="m-t30 sx-tilte">
                      {servicename.replaceAll("-", " ")}
                    </h4>
                    <div className="single-service-list">
                      <div className="row">
                        <div className="col-lg-6 col-md-12">
                          <ul className="list-angle-right anchor-line">
                            {services.map((s, index) => {
                              return <li>{s}</li>;
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <div className="sx-media sx-img-effect zoom-slow">
                      <div className="sx-thum-bx sx-img-overlay1 sx-img-effect yt-thum-box">
                        <img
                          src="https://img.youtube.com/vi/Oy2QIiSQT2U/0.jpg"
                          alt=""
                        />
                        <NavLink
                          to={"#"}
                          className="play-now"
                          data-toggle="modal"
                          data-target="#myModal6"
                        >
                          <i className="icon fa fa-play" />
                          <span className="ripple" />
                        </NavLink>
                      </div>
                    </div> */}
                  </div>
                  {/* <div
                    className="sx-accordion acc-bg-gray m-b30"
                    id="accordion5"
                  >
                    <div className="panel sx-panel">
                      <div className="acod-head acc-actives">
                        <h6 className="acod-title">
                          <a
                            data-toggle="collapse"
                            href="#collapseOne5"
                            data-parent="#accordion5"
                          >
                            Choose between rates or instant payment
                            <span className="indicator">
                              <i className="fa" />
                            </span>
                          </a>
                        </h6>
                      </div>
                      <div
                        id="collapseOne5"
                        className="acod-body collapse show"
                      >
                        <div className="acod-content p-a15">
                          <p>
                            {" "}
                            Motivate others and change the way we feel about
                            ourselves. This is why I find them so interesting
                            and crucial on our paths to success mauris accumsan
                            eros eget libero posuere vulputate. Etiam elit elit,
                            elementum sed varius at, adipiscing vitae est. Sed
                            nec felis pellentesque.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="panel sx-panel">
                      <div className="acod-head">
                        <h6 className="acod-title">
                          <a
                            data-toggle="collapse"
                            href="#collapseTwo5"
                            className="collapsed"
                            data-parent="#accordion5"
                          >
                            Come to see a live preview
                            <span className="indicator">
                              <i className="fa" />
                            </span>
                          </a>
                        </h6>
                      </div>
                      <div id="collapseTwo5" className="acod-body collapse">
                        <div className="acod-content p-a15">
                          <p>
                            Inspirational quotes have an amazing ability to
                            motivate others and change the way we feel about
                            ourselves. This is why I find them so interesting
                            and crucial on our paths to success.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="panel sx-panel">
                      <div className="acod-head">
                        <h6 className="acod-title">
                          <a
                            data-toggle="collapse"
                            href="#collapseThree5"
                            className="collapsed"
                            data-parent="#accordion5"
                          >
                            Choose the correct service
                            <span className="indicator">
                              <i className="fa" />
                            </span>
                          </a>
                        </h6>
                      </div>
                      <div id="collapseThree5" className="acod-body collapse">
                        <div className="acod-content p-a15">
                          <p>
                            The leap into electronic typesetting, remaining
                            essentially unchanged. It was popularised sheets
                            containing Lorem Ipsum passagese.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 m-b30">
                      <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                        <div className="sx-icon-box-lg inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                          <span className="icon-cell sx-text-primary">
                            <i className="flaticon-door" />
                          </span>
                        </div>
                        <div className="icon-content">
                          <h4 className="sx-tilte">Decoration</h4>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            setting as Planning.
                          </p>
                          <NavLink
                            to={"/services-1"}
                            className="site-button-link"
                          >
                            Read More
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 m-b30">
                      <div className="sx-icon-box-wraper p-a30  center bg-white shadow">
                        <div className="sx-icon-box-lg inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                          <span className="icon-cell sx-text-primary">
                            <i className="flaticon-ruler-1" />
                          </span>
                        </div>
                        <div className="icon-content">
                          <h4 className="sx-tilte">Solution</h4>
                          <p>
                            Lorem Ipsum is simply dummy text of the printing and
                            setting as Planning.
                          </p>
                          <NavLink
                            to={"/services-1"}
                            className="site-button-link"
                          >
                            Read More
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            {/* GALLERY CONTENT END */}
          </div>
        </div>
        {/* SECTION CONTENT END  */}
      </div>
      {/* <div className="modal fade" id="myModal6" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <ReactPlayer url="https://www.youtube.com/watch?v=Oy2QIiSQT2U" />
          </div>
        </div>
      </div> */}

      <Footer />
    </>
  );
};

export default ServiceDetail;
