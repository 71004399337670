import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Slider1 from "./../Elements/Slider1";
import About3 from "./../Elements/About3";
import Projects1 from "./../Elements/Projects1";
import ClientsLogo1 from "./../Elements/ClientsLogo1";
import WhatWeDo2 from './../Elements/WhatWeDo2';
import Achievements1 from "../Elements/Achievements1";
import SafetyPolicy from "../Elements/SafetyPolicy";
import Statistics1 from './../Elements/Statistics1';

class Home1 extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <section id="home">
            <Slider1 />
          </section>

          <section id="about">
          <About3 bgcolor="bg-white"/>
          <Achievements1 />
          </section>
          
          <section id="services">
            {/* <Services1 /> */}
            {/* <WhatWeDo1 /> */}
            <WhatWeDo2 />
          </section>
          <section id="projects">
            <Projects1 />
          </section>
          <section id="Clients">
            <ClientsLogo1 />
          </section>

          <section id="safetyPolicy">
            <SafetyPolicy />
          </section>
         <Statistics1 />
          <section id="location">
            <div className="section-full p-t80 p-b50 mobile-page-padding">
              <div className="container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3630.719787943772!2d54.37290407535916!3d24.49516397816956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDI5JzQyLjYiTiA1NMKwMjInMzEuNyJF!5e0!3m2!1sen!2slb!4v1724961698097!5m2!1sen!2slb"
                  width="100%"
                  height="450"
                  style={{ border: 0 }}
                  allowfullscreen=""
                  title="Al Diyar Location"
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </section>
        </div>
        <Footer />
      </>
    );
  }
}

export default Home1;
