import React from "react";


const Navigation = ({ setIsDrawerActive ,handleNavigation,setNavigationId,navigationId}) => {
 
  return (
    <ul className="nav navbar-nav">
      <li className={` ${navigationId === "home" ? "active" : ""}`}>
        <a href="/" onClick={(e) => handleNavigation(e, "home")}>
          Home
        </a>
      </li>
      <li className={` ${navigationId === "about" ? "active" : ""}`}>
        <a href="/" onClick={(e) => handleNavigation(e, "about")}>
          About us
        </a>
      </li>
      <li className={` ${navigationId === "services" ? "active" : ""}`}>
        <a href="/" onClick={(e) => handleNavigation(e, "services")}>
          Services
        </a>
      </li>
      <li className={` ${navigationId === "projects" ? "active" : ""}`}>
        <a href="/" onClick={(e) => handleNavigation(e, "projects")}>
          Projects
        </a>
      </li>
      <li>
                <a href="/" onClick={(e) => handleNavigation(e, 'safetyPolicy')}>Safety Policy</a>
            </li>
      <li className={` ${navigationId === "Clients" ? "active" : ""}`}>
        <a href="/" onClick={(e) => handleNavigation(e, "Clients")}>
          Clients
        </a>
      </li>
      <li className={` ${navigationId === "location" ? "active" : ""}`}>
        <a href="/" onClick={(e) => handleNavigation(e, "location")}>
          Our Location
        </a>
      </li>
    </ul>
  );
};

export default Navigation;
