import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home1 from "./Pages/Home1";
import ServiceDetail from "./Pages/ServiceDetail";
import BlogSingle from './Pages/BlogSingle';

import ScrollToTop from "./Common/ScrollToTop";

class Components extends React.Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <ScrollToTop />
        <div className="page-wraper">
          <Routes>
            <Route path="/*" element={<Home1 />} />
            <Route path="/about-us" element={<BlogSingle />} />
            <Route path="/service/:servicename" element={<ServiceDetail />} />
          </Routes>
        </div>
      </BrowserRouter>
    );
  }
}

export default Components;
