import React from "react";
import { NavLink } from "react-router-dom";

const services = [
  {
    count: "01",
    title: "Construction and Engineering",
    description: "",
  },
  {
    count: "02",
    title: "Electrical & Plumbing Works",
    description: "",
  },
  {
    count: "03",
    title: "Civil Works",
    description: "",
  },
  {
    count: "04",
    title: "Additional Services",
    description: "",
  },
];

var bgimg1 = require("./../../images/main-slider/img22.jpg");

class WhatWeDo2 extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full  mobile-page-padding bg-white  p-t80 p-b50 bg-repeat overflow-hide services-section"
          style={{ backgroundImage: "url(" + bgimg1 + ")" }}
        >
          <div className="container">
            <div className="large-title-block text-center">
              {/* TITLE START */}
              <div className="sx-separator-outer separator-left">
                <div className="sx-separator  bg-moving bg-repeat-x mision-vision-title">
                  <h3 className="sep-line-one" style={{ color: "white" }}>
                    Services
                  </h3>
                </div>
              </div>
              {/* TITLE END */}
            </div>
            <div className="section-content">
              <div className="row number-block-two-outer">
                {services.map((item, index) => (
                  <div
                    className="col-lg-3 col-md-6 col-sm-12 m-b30"
                    key={index}
                  >
                    <div className="number-block-two animate-in-to-top bdr-gray-light bdr-solid bdr-1">
                      <div className="figcaption bg-white  p-a30">
                        <h4 className={`m-t0 ${index === 2 ? "mt-26" : ""}`}>
                          {item.title}
                        </h4>
                        <p>{item.description}</p>
                        <NavLink
                          to={"/service/" + item.title.replaceAll(" ","-")}
                          className="site-button-link"
                        >
                          Read More
                        </NavLink>
                        <div className="figcaption-number animate-in-to-top-content">
                          <span>{item.count}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default WhatWeDo2;
