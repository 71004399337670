import React from "react";
import { NavLink } from 'react-router-dom';

var bgimg1 = require("./../../images/img521.jpg");

class About3 extends React.Component {
  render() {
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-content about-us-section">
              <div className="row">
                <div className="col-xl-7 col-xl-7 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <h3 className="m-t0 m-b20 sx-tilte">About Us</h3>
                    <p>
                      Al Diyar General Contracting Company L.L.C (DGC) commenced
                      its construction business in 1993 and has since grown into
                      one of the most trusted companies in the Abu Dhabi
                      Emirates.
                    </p>
                    <p>
                      Under the dedicated leadership and guidance of our
                      chairman, Mr. Hamad Al Junaibi, and with the excellent
                      teamwork of Mr. Kamil Sarieddine, Mr. Nadim Sarieddine,
                      Mr. Najib Sarieddine, and Eng. Naji Darwish, the company
                      has achieved significant recognition, attaining
                      classification in the 1st class category.
                    </p>
                    
                    <ul className="list-angle-right anchor-line">
                      <li>Sarieddine Trading</li>
                      <li>Al Diyar Marble Factory</li>
                    </ul>
                    <div className="text-left">
                      <NavLink to="/about-us" className="site-button btn-half">
                        <span>Read More</span>
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12">
                  <div className="video-section-full-v2">
                    <div
                      className="video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                      style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                    >
                      {/* <div className="overlay-main bg-black opacity-04" />
                      <div className="video-section-inner">
                        <div className="video-section-content">
                          <NavLink
                            to={"#"}
                            className="play-now"
                            data-toggle="modal"
                            data-target="#myModal"
                          >
                            <i className="icon fa fa-play" />
                            <span className="ripple" />
                          </NavLink>

                          <div className="video-section-bottom">
                            <h3 className="sx-title text-white">
                              25 Years
                              <br />
                              Experience
                            </h3>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default About3;
