import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

var bgimg1 = require("./../../images/Health-And-Safety-Policy-1.png");
var bgimg2 = require("./../../images/Health-And-Safety-Policy-2.jpeg");
class SafetyPolicy extends React.Component {
  render() {
    const options = {
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      margin: 30,
      nav: true,
      dots: false,
      navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
      ],
      responsive: {
        0: {
          items: 1,
        },
        991: {
          items: 1,
        },
      },
    };
    return (
      <>
        <div
          className={`${this.props.bgcolor} section-full mobile-page-padding p-t80 p-b50`}
        >
          <div className="container">
            <div className="section-content safety-policy-section">
              <div className="row">
                <div className="col-xl-5 col-lg-5 col-md-12">
                  <div className="video-section-full-v2">
                    <OwlCarousel
                      className="owl-carousel about-home number-slider owl-btn-vertical-center"
                      {...options}
                    >
                      <div
                        className="item video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                        style={{ backgroundImage: "url(" + bgimg1 + ")" }}
                      ></div>
                      <div
                        className="item video-section-full bg-no-repeat bg-cover bg-center overlay-wraper m-b30"
                        style={{ backgroundImage: "url(" + bgimg2 + ")" }}
                      ></div>
                    </OwlCarousel>
                  </div>
                </div>
                <div className="col-xl-7 col-lg-7 col-md-12">
                  <div className="about-home-3 m-b30 bg-white">
                    <h3 className="m-t0 m-b20 sx-tilte">
                      Health and Safety Policy
                    </h3>
                    <p>
                      At Al Diyar Contracting Co., we recognize the health and
                      safety responsibilities of employers, managers,
                      supervisors, and employees within our organization. We
                      ensure that all employees fulfill their roles in
                      implementing personnel management so that everyone in the
                      workplace is aware of their health and safety
                      responsibilities, as outlined in the company's
                      organizational policies and program.
                    </p>
                    <p>
                      Al Diyar Contracting Co. has established administrative
                      procedures that encourage employees to report unsafe
                      conditions and practices to their supervisors without fear
                      of disciplinary action. This approach fosters a safety
                      culture in which managers, supervisors, and employees
                      collaborate to ensure a safe working environment.
                    </p>
                    <p>
                      We take the necessary precautions and provide a
                      satisfactory working environment because health and safety
                      at work are paramount. Our safety rules and regulations
                      require everyone to avoid endangering themselves and
                      others, ultimately helping to save lives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SafetyPolicy;
