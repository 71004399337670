import React, { useState, useEffect } from "react";
import Navigation from "../Common/Navigation";
import { NavLink, useNavigate } from "react-router-dom";

const bnr = require("./../../images/background/bg-map.png");

const Header = () => {
  const logo = require("./../../images/Untitled-1.png");
  // const [logo, setLogo] = useState(require('./../../images/Al-Diyar-logo-removebg.png'));
  const [isQuoteActive, setIsQuoteActive] = useState(false);
  const [isDrawerActive, setIsDrawerActive] = useState(false);
  const [navigationId, setNavigationId] = useState("home");
  const navigate = useNavigate();

  const handleNavigation = (event, targetId) => {
    event.preventDefault();
    if (window.location.pathname !== "/") {
      navigate("/");
    }
    setTimeout(() => {
      const element = document.getElementById(targetId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        setNavigationId(targetId);
      }
    }, 0);
  };

  const handleQuoteToggle = () => {
    setIsQuoteActive(!isQuoteActive);
  };

  const handleDrawerToggle = () => {
    setIsDrawerActive(!isDrawerActive);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      const stickyheader = document.querySelector(".sticky-header");

      if (offset >= 100) {
        stickyheader.classList.add("is-fixed");
        stickyheader.classList.add("color-fill");
      } else {
        stickyheader.classList.remove("is-fixed");
        stickyheader.classList.remove("color-fill");
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup on component unmount
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      <header
        className={`site-header header-style-1 nav-wide mobile-sider-drawer-menu ${
          isDrawerActive ? "active" : ""
        }`}
      >
        <div className="top-bar bg-gray">
          <div className="container">
            <div className="d-flex justify-content-end">
              <ul className="list-unstyled e-p-bx">
                <li>
                  <span>Mail us:</span>{" "}
                  <a
                    style={{ color: "black" }}
                    href="mailto:aldiygen@aldiygen.ae"
                  >
                    aldiygen@aldiygen.ae
                  </a>
                </li>
                <li>
                  <span>Call us:</span>
                  <a style={{ color: "black" }} href="tel:+971 2 6743939">
                    +971 2 6743939
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="sticky-header main-bar-wraper navbar-expand-lg">
          <div className="main-bar header-left-gray-block bg-white">
            <div className="container clearfix">
              <div className="logo-header">
                <div className="logo-header-inner logo-header-one">
                  <NavLink
                    to={"/"}
                    onClick={(e) => handleNavigation(e, "home")}
                  >
                    <img src={logo} alt="aldiyar" />
                  </NavLink>
                </div>
              </div>
              {/* NAV Toggle Button */}
              <button
                id="mobile-side-drawer"
                type="button"
                className={`navbar-toggler ${isDrawerActive ? "active" : ""}`}
                onClick={handleDrawerToggle}
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar icon-bar-first" />
                <span className="icon-bar icon-bar-two" />
                <span className="icon-bar icon-bar-three" />
              </button>
              {/* EXTRA NAV */}
              <div className="extra-nav">
                <div className="extra-cell">
                  <div className="contact-slide-show">
                    <NavLink
                      to={"#"}
                      className="get-in-touch-btn from-top"
                      onClick={handleQuoteToggle}
                    >
                      Get in touch
                    </NavLink>
                  </div>
                </div>
              </div>
              <div
                className={`header-nav nav-dark navbar-collapse collapse justify-content-start ${
                  isDrawerActive ? "active" : ""
                }`}
              >
                <Navigation
                  setIsDrawerActive={setIsDrawerActive}
                  handleNavigation={handleNavigation}
                  navigationId={navigationId}
                  setNavigationId={setNavigationId}
                />
              </div>
              <div
                className="contact-slide-hide"
                style={{
                  backgroundImage: `url(${bnr})`,
                  right: isQuoteActive ? "0px" : "100%",
                }}
              >
                <div className="contact-nav">
                  <NavLink
                    to={"#"}
                    className="contact_close"
                    onClick={handleQuoteToggle}
                  >
                    ×
                  </NavLink>
                  <div className="contact-nav-form">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="contact-nav-info">
                          <div className="sx-icon-box-wraper left p-b30">
                            <div className="icon-xs inline-icon m-b20 scale-in-center">
                              <i className="fa fa-phone" />
                            </div>
                            <div className="icon-content">
                              <h6 className="m-t0">Phone number</h6>
                              <p>
                                <a href="tel:+971 2 6743939">+971 2 6743939</a>
                              </p>
                            </div>
                          </div>
                          <div className="sx-icon-box-wraper left p-b30">
                            <div className="icon-xs inline-icon m-b20 scale-in-center">
                              <i className="fa fa-fax" />
                            </div>
                            <div className="icon-content">
                              <h6 className="m-t0">Fax</h6>
                              <p>
                                <a href="tel:+971 2 6743936">+971 2 6743936</a>
                              </p>
                            </div>
                          </div>
                          <div className="sx-icon-box-wraper left p-b30">
                            <div className="icon-xs inline-icon m-b20 scale-in-center">
                              <i className="fa fa-envelope" />
                            </div>
                            <div className="icon-content">
                              <h6 className="m-t0">Email address</h6>
                              <p>
                                <a href="mailto:aldiygen@aldiygen.ae">
                                  aldiygen@aldiygen.ae
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12">
                        <div className="contact-nav-info">
                          <div className="sx-icon-box-wraper left p-b30">
                            <div className="icon-xs inline-icon m-b20 scale-in-center">
                              <i className="fa fa-map-marker" />
                            </div>
                            <div className="icon-content">
                              <h6 className="m-t0">Address info</h6>
                              <p>Abu Dhabi , Tourist Club Area</p>
                              <p>Sarieddine Trading Building, Floor 0</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
