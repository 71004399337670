import React, { useState , useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const filters = [
  { label: "Residential and Furnished Buildings", filter: "col-one" },
  { label: "Private Villas & Buildings", filter: "col-two" },
  { label: "Commercial Towers", filter: "col-three" },
];

const projects = [
  { image: require("./../../images/projects/Allprojects/res1.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res2.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res3.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res4.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res5.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res6.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res7.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res8.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res9.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res10.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res11.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res12.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res13.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res14.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res15.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res16.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res17.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/res18.jpg"), filter: "col-one" },
  { image: require("./../../images/projects/Allprojects/prv1.jpg"), filter: "col-two" },
  { image: require("./../../images/projects/Allprojects/prv2.jpg"), filter: "col-two" },
  { image: require("./../../images/projects/Allprojects/prv3.jpg"), filter: "col-two" },
  { image: require("./../../images/projects/Allprojects/prv4.jpg"), filter: "col-two" },
  { image: require("./../../images/projects/Allprojects/prv5.jpg"), filter: "col-two" },
  { image: require("./../../images/projects/Allprojects/prv6.jpg"), filter: "col-two" },
  { image: require("./../../images/projects/Allprojects/prv7.jpg"), filter: "col-two" },
  { image: require("./../../images/projects/Allprojects/prv8.jpg"), filter: "col-two" },
  { image: require("./../../images/projects/Allprojects/prv9.jpg"), filter: "col-two" },
  { image: require("./../../images/projects/Allprojects/comm1.jpg"), filter: "col-three" },
  { image: require("./../../images/projects/Allprojects/comm2.jpg"), filter: "col-three" },
  { image: require("./../../images/projects/Allprojects/comm3.jpg"), filter: "col-three" },
  { image: require("./../../images/projects/Allprojects/comm4.jpg"), filter: "col-three" },
  { image: require("./../../images/projects/Allprojects/comm5.jpg"), filter: "col-three" },
];

const Projects1 = () => {
  const [selectedFilter, setSelectedFilter] = useState(filters[0].filter);
  const [filteredProjects,setFilteredProjects]=useState(projects.filter((project) => project.filter === selectedFilter))

  const handleFilterChange = (filter) => {
    console.log(filter)
    setSelectedFilter(filter);
    setFilteredProjects(projects.filter((project) => project.filter === filter)
  );
  };

  useEffect(()=>{
    setFilteredProjects(projects.filter((project) => project.filter === selectedFilter)
  );
  },[selectedFilter])

  

  const options = {
    loop: false,
    autoplay: false,
    margin: 30,
    nav: true,
    dots: false,
    navText: [
      '<i class="fa fa-angle-left"></i>', // Left arrow
      '<i class="fa fa-angle-right"></i>', // Right arrow
    ],
    responsive: {
      0: {
        items: 1,
      },
      540: {
        items: 2,
      },
      768: {
        items: 3,
      },
      991: {
        items: 3,
      },
      1136: {
        items: 4,
      },
      1366: {
        items: 5,
      },
    },
  };

  return (
    <div className="section-full mobile-page-padding  p-b50 bg-white">
      {/* <div className="section-full mobile-page-padding p-t80 p-b50 bg-white"> */}
      <div className="section-content">
        <div className="container">
          {/* TITLE START */}
          <div className="section-head">
            <div className="sx-separator-outer separator-center">
              <div
                className="sx-separator bg-moving bg-repeat-x"
              >
                <h3 className="sep-line-one">All Projects</h3>
              </div>
            </div>
          </div>
          {/* TITLE END */}
        </div>
        <div className="filter-carousal-1-outer relative z-index1">
          <div className="container">
            {/* TITLE START */}
            <div className="text-center clearfix filter-pos-right shadow m-b30">
              <ul className="btn-filter-wrap">
                {filters.map((item, index) => (
                  <li
                    key={index}
                    className={`btn-filter ${
                      selectedFilter === item.filter ? "btn-active" : ""
                    }`}
                    onClick={() => handleFilterChange(item.filter)}
                  >
                    {item.label}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* TITLE END */}
          <div className="filter-carousal-1 container-fluid p-b30">
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <OwlCarousel
                className="owl-carousel owl-carousel-filter owl-btn-bottom-center mfp-gallery navigation-with-name"
                {...options}
              >
                {filteredProjects.map((item, index) => (
                  <div
                    key={index}
                    className={`${item.filter} item fadingcol overflow-hide`}
                  >
                    <div className="sx-box image-hover-block">
                      <div className="sx-thum-bx">
                        <img
                          src={item.image}
                          alt=""
                          style={{ height: "300px" }}
                        />
                      </div>
                      <div className="sx-info p-t20 text-white"></div>
                      <a className="mfp-link" href={item.image}>
                        <i className="fa fa-arrows-alt"></i>
                      </a>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects1;
