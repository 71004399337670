import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";

//var bnrimg = require("./../../images/banner/10.jpg");
var bnrimg = require('./../../images/new-images/construction-works-frankfurt-downtown-germany.jpg');

class BlogSingle extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/custom.js");
  }
  render() {
    return (
      <>
        <Header />
        <div className="page-content ">
          <Banner
            title="About Us"
            pagename="Blog Single"
            description="A multi-facetted company participating in a variety of industries. All two divisions of Al Diyar General Contracting provides superior service to clients with needs in Land Contracting & General Maintenance."
            bgimage={bnrimg}
          />
          {/* SECTION CONTENT START */}
          <div className="section-full p-t80 p-b50 inner-page-padding">
            <div className="container">
              <div className="blog-single-space max-w900 ml-auto mr-auto">
                {/* BLOG START */}
                <div className="blog-post blog-detail text-black">
                  <div className="sx-post-media">
                    <div className="portfolio-item">
                      <img
                        className="img-responsive"
                        src={require("./../../images/new-images/construction-silhouette.jpg")}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="sx-post-meta  m-t20">
                    {/* <ul>
                      <li className="post-date">
                        <strong>20 </strong> <span>Septembar 2022</span>{" "}
                      </li>
                      <li className="post-author">
                        <NavLink to={"#"}>
                          By <span>Admin</span>
                        </NavLink>{" "}
                      </li>
                      <li className="post-category">
                        <NavLink to={"#"}>
                          <span>Architecture</span>
                        </NavLink>{" "}
                      </li>
                    </ul> */}
                  </div>
                  {/* <div className="sx-post-title ">
                    <h3 className="post-title">
                    About Us
                    </h3>
                  </div> */}
                  <div className="sx-post-text">
                    <p>
                    Al Diyar General Contracting Company L.L.C (DGC) commenced
                      its construction business in 1993 and has since grown into
                      one of the most trusted companies in the Abu Dhabi
                      Emirates.
                    </p>
                    <p>
                      Under the dedicated leadership and guidance of our
                      chairman, Mr. Hamad Al Junaibi, and with the excellent
                      teamwork of Mr. Kamil Sarieddine, Mr. Nadim Sarieddine,
                      Mr. Najib Sarieddine, and Eng. Naji Darwish, the company
                      has achieved significant recognition, attaining
                      classification in the 1st class category.
                    </p>
                    <p></p>
                    {/* <blockquote className="bdr-1 bdr-solid bdr-gray author-quote">
                      <h4 className="m-b0">
                        We let our quality work and commitment to customer
                        satisfaction be our slogan. quality you deserve and
                        dependability you can count on.
                        <i className="fa fa-quote-left" />{" "}
                      </h4>
                      <div className="p-t15">
                        <strong>Jessica Mcdade</strong>
                        <span>Interior Designer</span>
                      </div>
                    </blockquote> */}
                    
                    <div className="row">
                      <div className="col-md-6">
                        <div className="sx-box m-b30">
                          <div className="sx-media">
                            <img
                              src={require("./../../images/new-images/back-view-man-holding-building-plans.jpg")}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="sx-box m-b30">
                          <div className="sx-media">
                            <img
                              src={require("./../../images/new-images/beautiful-view-construction-site-city-sunset.jpg")}
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>
                      For over 17 years, Al Diyar General Contracting Co. has
                      executed a diverse range of projects. Our portfolio
                      includes multi-storey commercial buildings, office
                      buildings, palaces, luxury villas, commercial residential
                      villas, and military projects. These have all been
                      completed to the highest standards under the supervision
                      of our highly qualified and experienced staff, skilled
                      laborers, and with a commitment to transforming our
                      clients’ dreams into reality.
                    </p>
                    <p>
                      Our commitment to service excellence and adherence to
                      International Standards of Practice has enabled us to
                      consistently deliver superior quality and performance. Our
                      professional and skilled staff prioritize quality, safety,
                      and excellence to ensure optimal client satisfaction.
                    </p>
                    <p>
                      Our mission is to provide speedy and efficient service to
                      maintain the trust and confidence of our valued clients.
                      We are proud to serve our clients with pride and honor,
                      and we look forward to a prosperous future.
                    </p>
                    <p>
                      In addition to our general contracting services, Al Diyar
                      Gen. Cont. Co. has supporting divisions, including a
                      marble and stone factory, and an interior decoration
                      division. We are also associated with our sister
                      companies:
                    </p>
                    <ul className="list-angle-right anchor-line">
                      <li>Sarieddine Trading</li>
                      <li>Al Diyar Marble Factory</li>
                    </ul>
                  </div>
                 
                </div>
              
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </>
    );
  }
}

export default BlogSingle;
